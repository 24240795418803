/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
import { LANGUAGES_CODE, FR_TAX_TYPE_LABELS } from "./constants"
const toFixed = (num, fixed, returnInt = false) => {
  fixed = fixed || 0
  const multiplier = Math.pow(10, fixed)
  num = typeof num === 'string' ? parseFloat(num) : num
  if (num === 0) {
    return '0.00'
  }
  if (!isNaN(num)) {
    num = (Math.round(num * multiplier) / multiplier).toFixed(fixed)
    return returnInt ? Math.round(num * 100) : num
  }
  return ''
}

const getPromoDiscountDollars = (promoDiscountCents) => {
  // Convert promo discount to dollars
  const promoDiscountDollars = promoDiscountCents / 100
  // Get absolute value of promo discount
  const absolutePromoDiscount = Math.abs(promoDiscountDollars)
  // Format promo discount to two decimal places
  const formattedPromoDiscount = parseFloat(absolutePromoDiscount.toFixed(2))

  return formattedPromoDiscount === 0 ? 0 : -1 * formattedPromoDiscount
}

const isNullOrEmpty = (value) => {
  if (value === '' || value === null || value === undefined) {
    return true
  }
  return false
}

const getTaxResponse = (responseTax) => {
    let val = 0
    responseTax?.data?.map(({chargeable }) => {
      val+= chargeable
    })
     return `${toFixed(val, 2)}`
}

const getTaxDetails = (responseTax, selectedLanguage) => {
  return responseTax?.data?.map(({ tax, value, chargeable }) => {
    const label = selectedLanguage === LANGUAGES_CODE.EN ? tax : FR_TAX_TYPE_LABELS[tax?.toUpperCase()]
    return {
      label: `${label} (${toFixed(value * 100, 2)}%)`,
      value: `${toFixed(chargeable, 2)}`
    }
  })
}

const containsProvince = (provinces, province) => {
  return provinces?.some((prov) => prov?.toLowerCase() === province?.toLowerCase())
}

export { toFixed, getPromoDiscountDollars, isNullOrEmpty, getTaxResponse, getTaxDetails, containsProvince }
