/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/extensions */
import Input from '@apps/components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { ContentContext } from '@apps/contexts/ContentContext'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { updateSimSelectionState } from '@apps/redux/features/SimSelectionSlice'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { useHistory } from 'react-router-dom'
import { getById, getBySlug } from '@apps/utils/contentful'
import Alert from '@apps/components/Alert'
import React, { useContext, useMemo, useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { BrandName } from '@apps/utils/constants'
import Heading4 from '@/apps/components/Typography/Heading4'
import PhoneDetails from '@/apps/components/PhoneDetails'
import Button from '@/apps/components/Button'
import Link from '@/apps/components/Link'
import BodyBold from '@/apps/components/Typography/BodyBold'

const MAX_EID = 32

const DealerESIMCompatibility = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dealerActivationPaths = new DealerActivationPaths()

  const simSelectionState = useSelector((state) => state.simSelectionPage)
  const { pageContent } = useSelector((state) => state.common.content)
  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.SERVICES]: { getDeviceInfo }
  } = useContext(ApplicationContext)
  const haveSimPageContent = getById(pageContent, `haveSimPage`)[language]
  const { shortTextFields } = haveSimPageContent

  const { dynamicContent } = useContext(ContentContext)
  const { haveSimPage = {} } = dynamicContent || {}
  const { content } = haveSimPage

  const { imei, eid } = simSelectionState

  const [dealerEID, setDealerEID] = useState('')
  const [deviceInfo, setDeviceInfo] = useState()
  const [customerIMEI, setCustomerIMEI] = useState('')
  const [disableIMEI, setDisableIMEI] = useState(true)
  const [foundIMEI, setIMEIFound] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isOpenImeiChecker, setIsOpenImeiChecker] = useState(false)

  const pageTextContent = useMemo(() => {
    if (!content && !shortTextFields) return {}
    return {
      infoIcon: getById(content, 'info-icon')?.image?.url,
      infoIconAlt: getById(content, 'info-icon')?.image?.title,
      iPhone: getById(content, 'iphone-xr')?.image?.url,
      zte: getById(content, 'zte')?.image?.url,
      checkESimCompatibility: getBySlug(shortTextFields, 'checkESimCompatibility')?.value,
      findEIDNumber: getBySlug(shortTextFields, 'findEIDNumber')?.value,
      findEIDNumberStep1: getBySlug(shortTextFields, 'findEIDNumberStep1')?.value,
      findEIDNumberStep2: getBySlug(shortTextFields, 'findEIDNumberStep2')?.value,
      findEIDNumberStep3: getBySlug(shortTextFields, 'findEIDNumberStep3')?.value,
      scanEIDInputLabel: getBySlug(shortTextFields, 'scanEIDInputLabel')?.value,
      imeiInputLabel: getBySlug(shortTextFields, 'imeiInputLabel')?.value,
      checkIMEI: getBySlug(shortTextFields, 'checkIMEI')?.value,
      eidErrorTitle: getBySlug(shortTextFields, 'eidErrorTitle')?.value,
      imeiNotFound: getBySlug(shortTextFields, 'imeiNotFound')?.value,
      imeiNotFoundMessage: getBySlug(shortTextFields, 'imeiNotFoundMessage')?.value,
      proceedWithESIM: getBySlug(shortTextFields, 'proceedWithESIM')?.value,
      openImeiChecker: getBySlug(shortTextFields, 'openImeiChecker')?.value,
      closeImeiChecker: getBySlug(shortTextFields, 'closeImeiChecker')?.value
    }
  }, [content, shortTextFields])

  const updateIMEICompatibilityCheck = (newState) => {
    dispatch(updateSimSelectionState(newState))
  }

  const handleInfoClick = (e) => {
    e.stopPropagation()
  }

  const onEIDNumberChange = (e) => {
    const { value } = e.target
    const eidValue = value?.trim()
    setDealerEID(eidValue)
    const validEID = eidValue.length === MAX_EID
    if (validEID) {
      updateIMEICompatibilityCheck({
        eid: eidValue
      })
    } else {
      updateIMEICompatibilityCheck({
        eid: ''
      })
    }
  }

  useEffect(() => {
    updateIMEICompatibilityCheck({
      eid: ''
    })
  }, [])

  const onIMEIChange = (e) => {
    const { value } = e.target
    setCustomerIMEI(value)
    setDisableIMEI(!(value.length >= 14 && value.length <= 15))

    if (value.length === 0) {
      setIMEIFound(null)
      updateIMEICompatibilityCheck({
        imei: '',
        imeiChecked: false,
        imeiCompatible: false
      })
    }
  }

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  const lookupIMEI = async () => {
    const parsedIMEI = customerIMEI?.trim()

    setLoading(true)
    const deviceInfoResponse = await getDeviceInfo(BrandName, parsedIMEI)
    const { data = {} } = deviceInfoResponse

    const { imageURL, enabledESIM, voLteSupportStatusCode, manufacturerName, modelName } = data
    let isIMEICompatible = false
    if (enabledESIM?.toUpperCase() === 'TRUE') {
      isIMEICompatible = true
    }

    if (manufacturerName && modelName) {
      setIMEIFound(true)
    } else {
      setIMEIFound(false)
    }

    updateIMEICompatibilityCheck({
      imei: parsedIMEI,
      imeiChecked: true,
      imeiCompatible: isIMEICompatible
    })

    const deviceInfoData = {
      imageURL,
      eSimCompatible: isIMEICompatible,
      lteEnabled: voLteSupportStatusCode === 'TRUE',
      manufacturer: manufacturerName,
      model: modelName
    }
    setDeviceInfo(deviceInfoData)
    setLoading(false)
  }

  const imeiLookupFailed = () => {
    return foundIMEI === false
  }

  const imeiLookupSucceeded = () => {
    return foundIMEI === true
  }

  const proceedToNextStep = () => {
    history.push(dealerActivationPaths.ChooseNumber)
  }

  const handleImeiChecker = () => {
    setIsOpenImeiChecker(!isOpenImeiChecker)
  }

  const imeiLookUpFeedback = () => {
    return (
      <>
        {imeiLookupSucceeded() && deviceInfo && (
          <div className="pt-6">
            <PhoneDetails
              phoneImage={deviceInfo?.imageURL}
              eSimCompatible={deviceInfo?.eSimCompatible}
              lteCompatible={deviceInfo?.lteEnabled}
              imei={imei}
              manufacturer={deviceInfo?.manufacturer}
              model={deviceInfo?.model}
              showIncompatibleMessage={false}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div className="pt-5 pb-4 pl-4 pr-4 sm:pl-8 sm:pb-8 sm:pr-8 border border-silver mt-2.5 text-black">
      <div className="flex justify-between w-full">
        <Heading4 className="text-medium font-h3 leading-6 text-left">
          {`${pageTextContent?.checkESimCompatibility} `}
        </Heading4>
        <div>
          <img
            className="z-10 w-7 min-w-min"
            alt={pageTextContent?.infoIconAlt}
            src={pageTextContent?.infoIcon}
            onKeyDown={handleInfoClick}
            onClick={handleInfoClick}
          />
        </div>
      </div>
      <hr className="mt-7" />
      <div className="mb-6 p-6 bg-white border-2 border-l-8 border-tertiary border-r mt-6">
        <p className="text-sm font-h3 mb-4 text-black">{pageTextContent?.findEIDNumber}</p>
        <ol className="list-decimal text-black text-sm font-body ml-6 leading-6">
          <li>{pageTextContent?.findEIDNumberStep1}</li>
          <li>{pageTextContent?.findEIDNumberStep2}</li>
          <li>{pageTextContent?.findEIDNumberStep3}</li>
        </ol>
      </div>
      {isMobile && isOpenImeiChecker && imeiLookUpFeedback()}
      <div className="flex flex-col">
        <div className="w-full mb-6">
          <Input
            type="text"
            id="input-imei"
            label={pageTextContent?.scanEIDInputLabel}
            labelStyles="font-body"
            name="EID"
            value={dealerEID}
            onChange={onEIDNumberChange}
            maxLength={MAX_EID.toString()}
            onKeyPress={handleKeyPress}
            data-testid="sim-selection-enter-your-eid-input"
            autoFocus
          />
        </div>

        {isOpenImeiChecker && (
          <div className="flex flex-col sm:flex-row items-end w-full space-x-4 mb-0">
            <div className="w-full sm:w-3/4">
              <Input
                type="text"
                id="input-imei"
                label={pageTextContent?.imeiInputLabel}
                labelStyles="font-body"
                name="IMEI"
                maxLength="15"
                value={customerIMEI}
                onChange={onIMEIChange}
                onKeyPress={handleKeyPress}
                data-testid="sim-selection-enter-your-imei-input"
              />
            </div>
            <div className="w-full mt-4 sm:w-1/4">
              <Button
                onClick={() => lookupIMEI()}
                label={pageTextContent?.checkIMEI}
                disabled={disableIMEI}
                isLoading={loading}
                internalLoader
                className="w-full font-h4 h-11 text-base flex justify-center items-center rounded-md"
              />
            </div>
          </div>
        )}
        <div className={`${isOpenImeiChecker ? 'mt-6' : ''} flex flex-row md:justify-between`}>
          <Link onClick={() => handleImeiChecker()}>
            <BodyBold className="underline leading-4" textColor="text-primary">
              {isOpenImeiChecker
                ? pageTextContent?.closeImeiChecker
                : pageTextContent?.openImeiChecker}
            </BodyBold>
          </Link>
        </div>
      </div>
      {isOpenImeiChecker && imeiLookupFailed() && (
        <div className="mt-6 sm:mt-0">
          <Alert
            header={pageTextContent?.imeiNotFound}
            message={pageTextContent?.imeiNotFoundMessage}
          />
          <div className="w-full">
            {eid && (
              <Button
                onClick={proceedToNextStep}
                label={pageTextContent?.proceedWithESIM}
                className="w-full font-h4 h-11 text-base flex justify-center items-center rounded-md"
              />
            )}
          </div>
        </div>
      )}
      {!isMobile && isOpenImeiChecker && imeiLookUpFeedback()}
    </div>
  )
}

export default DealerESIMCompatibility
