
const getTimestamp = () => {
  const date = new Date()
  const hour = `0${date.getHours()}`.slice(-2)
  const minutes = `0${date.getMinutes()}`.slice(-2)
  const seconds = `0${date.getSeconds()}`.slice(-2)
  const day = `0${date.getDate()}`.slice(-2)
  const month = `0${date.getMonth() + 1}`.slice(-2)
  const year = date.getFullYear()

  return `${year}${month}${day}${hour}${minutes}${seconds}`
}

export default {
  generateParams: async (activationNum, cardNumber, expiryDate, cvv2, postalCode) => {
    const timestamp = getTimestamp()

    return {
      amount: '0',
      creditCardNumber: cardNumber.replace(/\s/g, ''),
      expiryDate: expiryDate.replace('/', ''),
      cvv2,
      zip: postalCode,
      requestCode: 'verifyCreditCard',
      marketSegmentCode: 'I',
      orderId: `${timestamp}`,
      avsRequestCode: '1',
      cvv2RequestCode: '1',
      addToStorage: 'true',
      Timestamp: timestamp
    }
  }
}
