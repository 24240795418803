import React from 'react'
import PropTypes from 'prop-types'

export const SIZE = {
  SM: 'SM',
  LG: 'LG',
  MD: 'MD',
  MDS: 'MDS',
  SMW: 'SMW'
}

const inlineStyles = {
  [SIZE.MD]: {
    borderTop: '6px solid var(--color-primary)',
    borderRight: '6px solid var(--color-primary)',
    borderBottom: '6px solid var(--color-primary)',
    width: '2.5rem',
    height: '2.5rem'
  },
  [SIZE.LG]: {
    borderTop: '8px solid var(--color-primary)',
    borderRight: '8px solid var(--color-primary)',
    borderBottom: '8px solid var(--color-primary)',
    width: '2rem',
    height: '2rem'
  },
  [SIZE.SM]: {
    borderTop: '3px solid var(--color-primary)',
    borderRight: '3px solid var(--color-primary)',
    borderBottom: '3px solid var(--color-primary)',
    width: '1.25rem',
    height: '1.25rem'
  },
  [SIZE.MDS]: {
    borderTop: '4px solid var(--color-primary)',
    borderRight: '4px solid var(--color-primary)',
    borderBottom: '4px solid var(--color-primary)',
    width: '1.6rem',
    height: '1.6rem'
  },
  [SIZE.SMW]: {
    borderTop: '3px solid var(--color-copy-inverted)',
    borderRight: '3px solid var(--color-copy-inverted)',
    borderBottom: '3px solid var(--color-copy-inverted)',
    width: '1.25rem',
    height: '1.25rem'
  }
}

const Loader = ({ loading = false, className = '', size = SIZE.MD }) => {
  return (
    <>
      {loading && (
        <div
          style={inlineStyles[size]}
          data-testid="spinning-loader"
          className={`animate-spin w-10 h-10 rounded-50% border-md border-solid border-transparent ${className}`}
        />
      )}
    </>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.SM, SIZE.LG, SIZE.MD, SIZE.MDS, SIZE.SMW]),
  className: PropTypes.string
}

export default Loader
