/* eslint-disable import/extensions */
import React from 'react'
import SIM from '@apps/components/SIM'
import SIMCardNumber from '@apps/components/SIMCardNumber'
import PropTypes from 'prop-types'
import Box from '@/apps/components/Box'

const HaveSimCardDetail = ({ userType, simRef }) => {
  return (
    <Box border padding={8} className="-mx-7 sm:mx-0">
      <SIM simRef={simRef} className="pl-2" userType={userType} />
      <SIMCardNumber className="mt-5 pl-2" />
    </Box>
  )
}

HaveSimCardDetail.propTypes = {
  userType: PropTypes.string.isRequired,
  simRef: PropTypes.object.isRequired
}

export default HaveSimCardDetail
