/* eslint-disable import/extensions */
import React, {
  useState, useContext, useEffect, useRef, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import PaymentChangeHandlers, {
  PAYMENT_EVENTS
} from '@apps/utils/ChangeHandlers/PaymentChangeHandlers'
import { CARD_VALIDATION_ERRORS } from '@apps/utils/constants'
import Input from '@/apps/components/Input'
import { CardType, formatCreditCard, formatCreditCardAmex } from './helper'
import BodyError from '../Typography/BodyError'

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }
  return false
}

export const validateSecurityCode = (securityCode, cardNumber, expiryRef, textContent, updatePaymentStore) => {
  if (securityCode == null || securityCode === '') {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR]: textContent?.shortTextFields?.securityCodeRequired })
    expiryRef.current.scrollIntoView({
      behavior: "smooth"
    })
    return
  }
  if ((!CardType(cardNumber) || CardType(cardNumber) === 'amex') && securityCode.length !== 4) {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR]: textContent?.shortTextFields?.securityCodeAmex })
    expiryRef.current.scrollIntoView({
      behavior: "smooth"
    })
    return
  }
  if (CardType(cardNumber) && CardType(cardNumber) !== 'amex' && securityCode.length !== 3) {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR]: textContent?.shortTextFields?.securityCodeMC })
    expiryRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }
}

export const validateCardNumber = (cardNumber, ccRef, textContent, updatePaymentStore) => {
  if ((cardNumber == null || cardNumber === '') && ccRef.current) {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: textContent?.shortTextFields?.cardNumberRequired })
    ccRef.current.scrollIntoView({
      behavior: "smooth"
    })
    return
  }
  if (CardType(cardNumber) && CardType(cardNumber) === 'amex' && cardNumber.length !== 19) {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: textContent?.shortTextFields?.cardNumberInvalid })
    ccRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }
  if (CardType(cardNumber) && CardType(cardNumber) !== 'amex' && cardNumber.length !== 22) {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: textContent?.shortTextFields?.cardNumberInvalid })
    ccRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }
}

// Date format has to be MM/YY
export const validateCardExpiryDate = (expiryDate, expiryRef, textContent, updatePaymentStore) => {
  if (expiryDate == null || expiryDate === '') {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: textContent?.shortTextFields?.expiryDateRequired })
    expiryRef.current.scrollIntoView({
      behavior: "smooth"
    })
    return
  }

  if (expiryDate.length === 5 && expiryDate.includes('/')) {
    const dateArr = expiryDate.split('/')

    const currentDate = new Date()
    let cardDate = ''
    let isDateInPast = false

    if (dateArr.length === 2) {
      const validMonth = Number(dateArr[0]) >= 1 && Number(dateArr[0]) <= 12
      const currentYear = Number(`${new Date().getFullYear()}`.slice(-2))
      const validYear = Number(dateArr[1]) >= currentYear

      if (validMonth && validYear) {
        cardDate = new Date(`20${dateArr[1]}`, dateArr[0], 1, 0, 0, 0, 0)
        isDateInPast = dateInPast(cardDate, currentDate)
      }

      if (isDateInPast) {
        updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: textContent?.shortTextFields?.expiryDateExpired })
        expiryRef.current.scrollIntoView({
          behavior: "smooth"
        })
        return
      }

      if (validMonth && validYear) {
        // no error
        updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: '' })
        return
      }
      if (!validMonth) {
        updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: textContent?.shortTextFields?.expiryDateValidRequired })
        expiryRef.current.scrollIntoView({
          behavior: "smooth"
        })
      }

      if (!validYear) {
        updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: textContent?.shortTextFields?.expiryDateExpired })
        expiryRef.current.scrollIntoView({
          behavior: "smooth"
        })
      }
    } else {
      updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: textContent?.shortTextFields?.expiryDateInvalidFormat })
      expiryRef.current.scrollIntoView({
        behavior: "smooth"
      })
    }
  } else {
    updatePaymentStore({ [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: textContent?.shortTextFields?.expiryDateInvalidFormat })
    expiryRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }

}

const CreditDebitCard = ({ showErrors, textContent }) => {
  const dispatch = useDispatch()
  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage
  } = useContext(ApplicationContext)

  const {
    [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: cardNumber,
    [PAYMENT_STATE.CC_INFO.EXPIRY_DATE]: expiryDate,
    [PAYMENT_STATE.CC_INFO.SECURITY_CODE]: securityCode,
    [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: postalCode,
    [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: errorCardMessage,
    [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: expiryDateErrorMessage,
    [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR]: securityCodeErrorMessage,
    [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]: postalCodeErrorMessage,
    [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: ccApiValidationError
  } = useSelector((state) => state.payAndReviewPage)

  const paymentState = useSelector((state) => state.payAndReviewPage)

  const [cardType, setCardType] = useState('')

  const ccRef = useRef(null)
  const expiryRef = useRef(null)
  const postalRef = useRef(null)

  const cardApiValidationError = useMemo(() => {
    if (!ccApiValidationError) return ""
    if (ccApiValidationError === CARD_VALIDATION_ERRORS.UNAUTHORIZED) {
      return textContent?.shortTextFields?.paymentSessionExpiredError
    }
    return textContent?.shortTextFields?.cannotProcessCreditCard
  }, [currentLanguage, ccApiValidationError])

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const handleCardNumberOnBlur = () => {
    validateCardNumber(cardNumber, ccRef, textContent, updatePaymentStore)
  }


  const handleExpiryDateOnBlur = () => {
    validateCardExpiryDate(expiryDate, expiryRef, textContent, updatePaymentStore)
  }

  const handleExpiryDateChange = (e) => {
    e.preventDefault()
    const { value } = e.target
    let checkValue = value
    checkValue = checkValue.replace('/', '')
    const isNumber = /^\d+$/.test(checkValue)

    if (isNumber || e.target.value === "") {
      const sanitizedValue = value.replace(/[\s/]/g, '')
      const expiryArr = sanitizedValue.match(/.{1,2}/g)
      if (expiryArr && expiryArr.length > 0) {
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE]: expiryArr.join('/')})
      } else {
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE] : sanitizedValue})
      }
    }
  }

  const handleCardNumberFocus = (e) => {
    if (errorCardMessage !== '') {
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: ''})
    }
  }


  const handleCardNumberChange = (e) => {
    e.preventDefault()
    const { value } = e.target

    const sanitizedValue = value.replace(/[\s/]/g, '')
    const isNum = /^\d+$/.test(sanitizedValue) || sanitizedValue === ''
    let type

    if (isNum) {
      if (sanitizedValue.trim().length > 0) {
        type = CardType(value)
      }
      if (type === 'amex') {
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER] : formatCreditCardAmex(sanitizedValue)})
      } else {
        updatePaymentStore({[PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER] : formatCreditCard(sanitizedValue)})
      }
    }
  }

  const handleSecurityCodeChange = (e) => {
    e.preventDefault()
    const { value } = e.target

    const sanitizedValue = value.replace(/[\s/]/g, '')
    const isNum = /^\d+$/.test(sanitizedValue) || sanitizedValue === ''

    if (isNum) {
      updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE] : sanitizedValue})
    }
  }

  const handleExpiryDateFocus = () => {
    updatePaymentStore({[PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR] : ''})
  }

  const handleSecurityCardOnBlur = () => {
    validateSecurityCode(securityCode, cardNumber, expiryRef, textContent, updatePaymentStore)
  }
  const handleSecurityCodeFocus = () => {
    updatePaymentStore({[PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR] : ''})
  }

  const handlePostalCodeFocus = () => {
    updatePaymentStore({[PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR] : ''})
  }

   const handlePostalBlur = () => {
    return PaymentChangeHandlers[PAYMENT_EVENTS.POSTAL_CODE].onBlur(
      paymentState,
      {
        postalCodeRequired: textContent?.shortTextFields?.postalZipRequired ,
        postalCodeInvalid: textContent?.shortTextFields?.postalZipInvalid
      },
      updatePaymentStore
    )
  }

  useEffect(() => {
    if (showErrors) {
      if (PaymentChangeHandlers[PAYMENT_EVENTS.POSTAL_CODE].onBlur(
        paymentState,
        {
        postalCodeRequired: textContent?.shortTextFields?.postalZipRequired,
        postalCodeInvalid: textContent?.shortTextFields?.postalZipInvalid
      },
      updatePaymentStore
    )) {
        postalRef.current.scrollIntoView({
          behavior: "smooth"
        })
      }
      handleSecurityCardOnBlur()
      handleExpiryDateOnBlur()
      handleCardNumberOnBlur()
    }
  }, [showErrors])

  useEffect(() => {
    if (paymentState.creditCardNumberError) {
      handleCardNumberOnBlur()
    }
    if (paymentState.expiryDateError) {
      handleExpiryDateOnBlur()
    }
    if (paymentState.securityCodeError) {
      handleSecurityCardOnBlur()
    }
    if (paymentState.postalCodeError) {
      PaymentChangeHandlers[PAYMENT_EVENTS.POSTAL_CODE].onBlur(
        paymentState,
        {
        postalCodeRequired: textContent?.shortTextFields?.postalZipRequired,
        postalCodeInvalid: textContent?.shortTextFields?.postalZipInvalid
      },
      updatePaymentStore
    )
    }

  }, [currentLanguage])

  return (
    <div className="space-y-4">
      {cardApiValidationError && (
        <BodyError>
          {cardApiValidationError}
        </BodyError>
      )}
      <div ref={ccRef} className="w-full">
        <Input
          type="text"
          id="cardNumber"
          label={textContent?.shortTextFields?.cardNumber}
          name="cardNumber"
          value={cardNumber}
          onBlur={() => handleCardNumberOnBlur()}
          onChange={e => handleCardNumberChange(e)}
          onFocus={e => handleCardNumberFocus(e)}
          errorMessage={errorCardMessage}
          maxLength={cardType && cardType === 'amex' ? '19' : '22'}
          autoComplete="off"
          data-testid="payment-creditCard"
        />
      </div>
      <div ref={expiryRef} className="flex flex-col space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
        <Input
          type="text"
          id="expiryDate"
          label={textContent?.shortTextFields?.expiryDate}
          name="expiryDate"
          value={expiryDate}
          onBlur={() => handleExpiryDateOnBlur()}
          onChange={e => handleExpiryDateChange(e)}
          onFocus={e => handleExpiryDateFocus(e)}
          maxLength="5"
          autoComplete="off"
          errorMessage={expiryDateErrorMessage}
          data-testid="payment-creditExpiry"
        />
        <Input
          type="text"
          id="securityCode"
          label={textContent?.shortTextFields?.securityCode}
          name="securityCode"
          value={securityCode}
          onBlur={() => handleSecurityCardOnBlur()}
          onChange={e => handleSecurityCodeChange(e)}
          onFocus={e => handleSecurityCodeFocus(e)}
          errorMessage={securityCodeErrorMessage}
          maxLength={CardType(cardNumber) === 'amex' ? '4' : '3'}
          data-testid="payment-creditSecurity"
        />
      </div>
      <div ref={postalRef} className="w-full">
        <Input
          type="text"
          id="postalCodeCard"
          label={textContent?.shortTextFields?.postalCode}
          name="postalCodeCard"
          value={postalCode}
          onBlur={() => handlePostalBlur()}
          onChange={(e) => PaymentChangeHandlers[PAYMENT_EVENTS.POSTAL_CODE].onChange(e.target.value, updatePaymentStore)}
          onFocus={e => handlePostalCodeFocus(e)}
          errorMessage={postalCodeErrorMessage}
          autoComplete="off"
          maxLength="7"
          data-testid="payment-creditPostal"
        />
      </div>
    </div>
  )
}

export default CreditDebitCard
