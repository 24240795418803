/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { PAYMENT_OPTIONS } from '@apps/utils/constants'
import { isNullOrEmpty } from '@apps/utils/common'

export const PAYMENT_STATE = {
  RAF: {
    FRIEND_REFERRAL_CODE: 'friendReferralCode',
    FRIEND_REFERRAL_CODE_INFO: 'friendReferralCodeInfo',
    FRIEND_REFERRAL_CODE_IS_VALID: 'friendReferralCodeIsValid',
    FRIEND_REFERRAL_SETTINGS: 'friendReferralSettings'
  },
  PROMO: {
    PROMOTION_CODE: 'promotionCode',
    PROMOTION_CODE_INFO: 'promotionCodeInfo',
    PROMOTION_VALIDATION_CODE: 'promotionValidationCode',
    MULTI_PROMOTION_CODE: 'multiPromotionCode',
    MULTI_PROMOTION_CODE_LOADER: 'multiPromotionCodeLoader',
    MULTI_PROMOTION_CODE_INFO: 'multiPromotionCodeInfo',
    MULTI_PROMOTION_VALIDATION_CODE: 'multiPromotionValidationCode',
    MULTI_PROMO_CODES: 'multiPromoCodes'
  },
  VOUCHER: {
    ADDED_VOUCHERS: 'addedVouchers',
    INSUFFICIENT_AMOUNT_ERROR: 'insufficientAmountError'
  },
  SUMMARY: {
    AMOUNT_DUE: 'amountDue',
    AMOUNT_OWING: 'amountOwing',
    AMOUNT_FUNDS: 'amountFunds',
    AMOUNT_TOTAL_VOUCHER: 'amountTotalVoucher',
    AMOUNT_TAX: 'amountTax',
    TAX_INFO: 'taxInfo'
  },
  CC_INFO: {
    CREDIT_CARD_NUMBER: 'creditCardNumber',
    EXPIRY_DATE: 'expiryDate',
    SECURITY_CODE: 'securityCode',
    POSTAL_CODE: 'postalCode',
    CREDIT_CARD_NUMBER_ERROR: 'creditCardNumberError',
    EXPIRY_DATE_ERROR: 'expiryDateError',
    SECURITY_CODE_ERROR: 'securityCodeError',
    POSTAL_CODE_ERROR: 'postalCodeError',
    CC_API_VALIDATION_ERROR: 'ccApiValidationError',
    MASKED: 'masked'
  },
  TERMS_OF_SERVICE: 'termsOfService',
  VOUCHER_CODE: 'voucherCode',
  AUTO_TOP_UPS: 'autoTopUps',
  TAX: 'tax',
  SUB_TOTAL: 'subtotal',
  TOTAL: 'total',
  SELECTED_PAYMENT_OPTION: 'selectedPaymentOption',
  PAYMENT_GATEWAY: 'paymentGateway',
  PAYMENT_GATEWAY_DEALER: 'paymentGatewayDealer',
  PAYMENT_ALLOWED_COUNTRY_CODES: 'paymentAllowedCountryCodes',
  PAYMENT_CVV_RESP_VAL: 'paymentcvvResponseValue',
  PAYMENT_AVS_POSTAL_RESP_VAL: 'paymentAvsPostalResponse',
  PAYMENT_ADDRESS_RESP_VAL: 'paymentAddressResponseVal',
  PAYMENT_SCROLL_TO_ERR_FIELD: 'paymentScrollToErrField',
  TRIGGER_EPS_CARD_VALIDATION: 'triggerEPSCardValidation',
  IS_COMPLETE: 'isComplete',
  IS_COMPLETE_EPS: 'isCompleteEPS'
}

export const referAFriend = {
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: '',
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_INFO]: '',
  [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
}

export const promoCode = {
  [PAYMENT_STATE.PROMO.PROMOTION_CODE]: '',
  [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: '',
  [PAYMENT_STATE.PROMO.PROMOTION_VALIDATION_CODE]: ''
}

export const paymentVoucher = {
  [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: [],
  [PAYMENT_STATE.VOUCHER.INSUFFICIENT_AMOUNT_ERROR]: false
}

export const paymentSummary = {
  [PAYMENT_STATE.SUMMARY.AMOUNT_DUE]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_FUNDS]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: (0.0).toFixed(2),
  [PAYMENT_STATE.SUMMARY.TAX_INFO]: [],
  [PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]: (0.0).toFixed(2)
}

export const ccInfo = {
  [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: '',
  [PAYMENT_STATE.CC_INFO.EXPIRY_DATE]: '',
  [PAYMENT_STATE.CC_INFO.SECURITY_CODE]: '',
  [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: '',
  [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: '',
  [PAYMENT_STATE.CC_INFO.MASKED]: ''
}

let state

export const PaymentComplete = (newState) => {
  const flow = newState[PAYMENT_STATE.SELECTED_PAYMENT_OPTION]

  if (!newState[PAYMENT_STATE.TERMS_OF_SERVICE]) {
    return false
  }

  if (
    flow === PAYMENT_OPTIONS.CREDIT_CARD ||
    (flow === PAYMENT_OPTIONS.VOUCHER && newState[PAYMENT_STATE.AUTO_TOP_UPS])
  ) {
    const fields = [
      [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER],
      [PAYMENT_STATE.CC_INFO.EXPIRY_DATE],
      [PAYMENT_STATE.CC_INFO.SECURITY_CODE],
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE]
    ]
    const errors = [
      [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR],
      [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR],
      [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR],
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]
    ]

    const emptyRequiredField = fields.find((field) => isNullOrEmpty(newState[field]))
    if (emptyRequiredField) {
      return false
    }

    const hasError = errors.find((error) => !isNullOrEmpty(newState[error]))
    if (hasError) {
      return false
    }
    if (flow === PAYMENT_OPTIONS.VOUCHER && newState[PAYMENT_STATE.AUTO_TOP_UPS]) {

      // amount Number(amountDue) > 0 return false
      const diff = Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]) - Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_OWING])

      if (newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS] && newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].length > 0 && diff >= 0) {
        return true
      }
      return false
    }

    return true
  }


  if (flow === PAYMENT_OPTIONS.VOUCHER) {

    // amount Number(amountDue) > 0 return false
    const diff = Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]) - Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_OWING])

    if (newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS] && newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].length > 0 && diff >= 0) {
      return true
    }
  }

  return false
}

export const PaymentCompleteEPS = (newState) => {

  const flow = newState[PAYMENT_STATE.SELECTED_PAYMENT_OPTION]

  if (!newState[PAYMENT_STATE.TERMS_OF_SERVICE]) {
    return false
  }

  if (
    flow === PAYMENT_OPTIONS.CREDIT_CARD ||
    (flow === PAYMENT_OPTIONS.VOUCHER && newState[PAYMENT_STATE.AUTO_TOP_UPS])
  ) {
    const fields = [
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE]
    ]
    const errors = [
      [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER_ERROR],
      [PAYMENT_STATE.CC_INFO.EXPIRY_DATE_ERROR],
      [PAYMENT_STATE.CC_INFO.SECURITY_CODE_ERROR],
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR],
      [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]
    ]

    const emptyRequiredField = fields.find((field) => isNullOrEmpty(newState[field]))
    if (emptyRequiredField) {
      return false
    }

    const hasError = errors.find((error) => !isNullOrEmpty(newState[error]))
    if (hasError) {
      return false
    }
    if (flow === PAYMENT_OPTIONS.VOUCHER && newState[PAYMENT_STATE.AUTO_TOP_UPS]) {

      // amount Number(amountDue) > 0 return false
      const diff = Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]) - Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_OWING])

      if (newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS] && newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].length > 0 && diff >= 0) {
        return true
      }
      return false
    }

    return true
  }


  if (flow === PAYMENT_OPTIONS.VOUCHER) {

    // amount Number(amountDue) > 0 return false
    const diff = Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_TOTAL_VOUCHER]) - Number(newState[PAYMENT_STATE.SUMMARY.AMOUNT_OWING])

    if (newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS] && newState[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS].length > 0 && diff >= 0) {
      return true
    }
  }

  return false
}

export const getVouchers = (addedVouchers) => {
  return addedVouchers?.map((voucher) => voucher.id).join(', ')
}

export const getTotalPromoDiscounts = () => {
  const { bundles = [] } = state[PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]
  return bundles.reduce((a, b) => Number(a.fee) || 0 + Number(b.fee) || 0, 0)
}

/*
 * Event Functions
 */

export const PAYMENT_EVENTS = {
  VALIDATE_CODE: 'event.target.payment.validateCode',
  POSTAL_CODE: 'event.target.payment.postalCode'
}



export const payAndReviewSlice = createSlice({
  name: 'payAndReviewPage',
  initialState: {
    // TODO: Billing Postal Code will setup the province
      ...referAFriend,
      ...promoCode,
      ...paymentVoucher,
      ...paymentSummary,
      ...ccInfo,
      [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: [],
      [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE]: '',
      [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: [],
      [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: false,
      [PAYMENT_STATE.PROMO.MULTI_PROMOTION_VALIDATION_CODE]: '',


      [PAYMENT_STATE.TERMS_OF_SERVICE]: false,
      [PAYMENT_STATE.AUTO_TOP_UPS]: true,
      [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: PAYMENT_OPTIONS.CREDIT_CARD,
      [PAYMENT_STATE.RAF.FRIEND_REFERRAL_SETTINGS]: null,
      [PAYMENT_STATE.PAYMENT_GATEWAY]: 'EPS',
      [PAYMENT_STATE.PAYMENT_GATEWAY_DEALER]: 'EPS',
      [PAYMENT_STATE.PAYMENT_ALLOWED_COUNTRY_CODES]: ['124'],
      [PAYMENT_STATE.PAYMENT_CVV_RESP_VAL]: ['MATCHED'], 
      [PAYMENT_STATE.PAYMENT_AVS_POSTAL_RESP_VAL]: ['MATCHED'],
      [PAYMENT_STATE.PAYMENT_ADDRESS_RESP_VAL]: ['MATCHED', 'NOT_MATCHED', 'NOT_CHECKED'],
      [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false,
      [PAYMENT_STATE.PAYMENT_SCROLL_TO_ERR_FIELD]: null,
      [PAYMENT_STATE.IS_COMPLETE]: false,
      [PAYMENT_STATE.IS_COMPLETE_EPS]: false
      
  },
  reducers: {
    setPaymentInformation: (state, action) => {
      state.paymentInformation = action.payload
    },

    resetPaymentInformation: (state) => {
      state.paymentInformation = {
        paymentToken: '',
        promoCode: '',
        promoCodeId: '',
        rafCode: '',
        paymentPostalCode: '',
        cardNumber: '',
        expiry: '',
        billingProvinceCode: ''
      }
    },
    updatePaymentStates : (state, action) => {
        const newState = {
          ...state,
          ...action.payload,
        }
        const isComplete = PaymentComplete(newState)
        // EPS
        const isCompleteEPS = PaymentCompleteEPS(newState)

        return {
          ...newState,
          [PAYMENT_STATE.IS_COMPLETE] : isComplete,
          [PAYMENT_STATE.IS_COMPLETE_EPS] : isCompleteEPS
        }
    }

  }
})

// this is for dispatch
export const { setPaymentInformation, resetPaymentInformation, updatePaymentStates } = payAndReviewSlice.actions

// this is for configureStore
export default payAndReviewSlice.reducer
