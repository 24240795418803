import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Alert from '@apps/components/Alert'
import { getById, getBySlug } from '@apps/utils/contentful'

const SimPurchaseUnavailable = () => {
  const { pageContent } = useSelector((state) => state.common.content)
  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)
  const haveSimPageContent = getById(pageContent, `haveSimPage`)[language]
  const { shortTextFields } = haveSimPageContent

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      simPurchaseUnavailableTitle: getBySlug(shortTextFields, 'simPurchaseUnavailableTitle')?.value,
      simPurchaseUnavailableMessage: getBySlug(shortTextFields, 'simPurchaseUnavailableMessage')
        ?.value
    }
  }, [shortTextFields])

  return (
    <Alert
      header={textContent.simPurchaseUnavailableTitle}
      message={textContent?.simPurchaseUnavailableMessage}
    />
  )
}

export default SimPurchaseUnavailable
