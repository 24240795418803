/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/extensions */
import React, { useMemo, useContext } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getById } from '@apps/utils/contentful'
import Heading4 from '@/apps/components/Typography/Heading4'
import BodyMedium from '@/apps/components/Typography/BodyMedium'

const processStrikethroughText = (text) => {
  const regex = /~(.*?)~/g

  const parts = text.split(regex)

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return <del key={index}>{part}</del>
    }

    return <React.Fragment key={index}>{part}</React.Fragment>
  })
}

const Accordion = ({ title, subtitle, children, open, setOpen, onInfoClick, testId }) => {
  const { dynamicContent } = useContext(ContentContext)

  const { haveSimPage = {} } = dynamicContent || {}
  const { content } = haveSimPage

  const pageContent = useMemo(() => {
    if (!content) return {}
    return {
      infoIcon: getById(content, 'info-icon')?.image?.url,
      infoIconAlt: getById(content, 'info-icon')?.image?.title,
      chevronRight: getById(content, 'chevron-right')?.image?.url,
      chevronRightColored: getById(content, 'chevron-right-colored')?.image?.url
    }
  }, [content])

  const handleInfoClick = (e) => {
    e.stopPropagation()
    onInfoClick()
  }

  return (
    <div
      className={`py-5 pl-4 pr-4 sm:pl-8 sm:pr-8 border border-silver mt-2.5`}
      data-testid={testId}
    >
      <button type="button" className="block w-full" onClick={setOpen} data-testid={`${testId}-toggle`}>
        <div className="flex justify-between w-full">
          <div className="flex">
            <div className="mr-3 pt-0.5">
              <img
                alt="click here"
                src={open ? pageContent?.chevronRightColored : pageContent?.chevronRight}
                className={`transform origin-center transition duration-200 ease-out ${
                  open && 'rotate-90'
                }`}
              />
            </div>
            <div>
              <Heading4 className="text-medium font-h3 leading-6 text-left">
                {processStrikethroughText(title)}
              </Heading4>
              <BodyMedium className="leading-6 pt-1 text-left">{subtitle}</BodyMedium>
            </div>
          </div>
          <div>
            <img
              className="z-10 w-7 min-w-min"
              alt={pageContent?.infoIconAlt}
              src={pageContent?.infoIcon}
              onKeyDown={handleInfoClick}
              onClick={handleInfoClick}
              data-testid={`${testId}-info-button`}
            />
          </div>
        </div>
      </button>
      <div
        className={classNames(
          'grid overflow-hidden transition-all duration-300 ease-in-out text-black',
          open
            ? 'grid-rows-animate-height-open opacity-100'
            : 'grid-rows-animate-height-closed opacity-0'
        )}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  testId: PropTypes.string
}

export default Accordion
