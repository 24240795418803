import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import Datalist from '@apps/components/Datalist'
import CreateNewNumber from '@apps/components/CreateNewNumber'
import { useHistory } from 'react-router-dom'
import Loader from '@apps/components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import {
  ApplicationContext,
  APPLICATION_STATE,
  ModifyApplicationContext,
  getProvince,
  setLandingLogicLoader
} from '@apps/contexts/ApplicationContext'
import { getById, getBySlug } from '@apps/utils/contentful'
import PhoneNumberChangeHandlers, {
  PHONE_NUMBER_EVENTS
} from '@apps/utils/ChangeHandlers/PhoneNumberChangeHandlers'
import AccountSetupChangeHandlers, {
  ACCOUNT_SETUP_EVENTS
} from '@apps/utils/ChangeHandlers/AccountSetupChangeHandlers'
import {
  PHONE_NUMBER_STATE,
  updatePhoneNumberState,
  createNewNumberState,
  transferANumberCommonState,
  transferANumberWirelessState,
  transferANumberWirelineState
} from '@apps/redux/features/PhoneNumberSlice'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import { updateAccountSetupState } from '@apps/redux/features/AccountSetupSlice'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import Sites from '@sites/index'
import PlanUnavailablePopup from '@apps/components/PlanUnavailablePopup'
import PhoneNumberUnavailablePopup from '@apps/components/PhoneNumberUnavailablePopup'

const LocationSelector = ({ handleBlurProvince, handleBlurCity, cityRef, provinceRef }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { userType } = useSelector((state) => state.common)
  const { pageContent } = useSelector((state) => state.common.content)
  const {
    provinceId: quoteProvId,
    UserData,
    isPlanSelected
  } = useSelector((state) => state.common.quote.quoteInfo)

  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = useSelector(
    (state) => state.plansPage
  )
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)

  const {
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: phoneNumber,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: provinceGroupId,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: provinceGroupIdError,
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.CITY_NAME]: cityName,
    [PHONE_NUMBER_STATE.PROVINCE_ID]: provinceId,
    [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: provinceIdError
  } = phoneNumberState

  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const [isNumbersLoaded, setIsNumbersLoaded] = useState(!!UserData?.phoneNumber || !!phoneNumber)
  const [isCityLoading, setIsCityLoading] = useState(false)
  const [newNumber, setNewNumber] = useState(phoneNumber.id ? phoneNumber : '')
  const [cityValue, setCityValue] = useState('')
  const [validPlanSelected, setValidPlanSelected] = useState(true)
  const [showPhoneNumberUnavailable, setShowPhoneNumberUnavailable] = useState(false)
  const [showProvinceError, setShowProvinceError] = useState(true)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList,
    [APPLICATION_STATE.PROVINCE_GROUPS]: mappedProvinceGroupsList,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = useContext(ApplicationContext)

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const phoneNumberPageContent = getById(pageContent, `phoneNumberPage${pagePrefix}`) || {}
  const { shortTextFields } = phoneNumberPageContent[language] || {}

  const textContent = useMemo(() => {
    return {
      cityInvalid: getBySlug(shortTextFields, 'cityInvalid')?.value,
      cityLabel: getBySlug(shortTextFields, 'cityLabel')?.value,
      cityRequired: getBySlug(shortTextFields, 'cityRequired')?.value,
      unavailableNumberMessage: getBySlug(shortTextFields, 'unavailableNumberMessage')?.value,
      unavailableNumberTitle: getBySlug(shortTextFields, 'unavailableNumberTitle')?.value,
      provinceInvalid: getBySlug(shortTextFields, 'provinceInvalid')?.value,
      provinceLabel: getBySlug(shortTextFields, 'provinceLabel')?.value,
      provinceRequired: getBySlug(shortTextFields, 'provinceRequired')?.value,
      selectNewPhoneNumber: getBySlug(shortTextFields, 'selectNewPhoneNumber')?.value
    }
  }, [shortTextFields])

  const {
    cityLabel,
    cityInvalid,
    cityRequired,
    unavailableNumberMessage,
    unavailableNumberTitle,
    provinceLabel,
    provinceInvalid,
    provinceRequired,
    selectNewPhoneNumber
  } = textContent

  const errorCopy = {
    cityRequired,
    cityInvalid,
    provinceRequired,
    provinceInvalid
  }

  const [provinceValue, setProvinceValue] = useState('')

  const appState = useContext(ApplicationContext)
  const setAppState = useContext(ModifyApplicationContext)

  let provinces = []
  if (mappedProvinceList) {
    provinces = mappedProvinceList.map((item) => {
      const { label, value, shortName } = item
      return {
        value,
        name: label[language],
        shortName
      }
    })
  }

  const currProvince = useMemo(() => {
    if (!provinceId) return ''
    const { shortName } = getProvince(provinceId)
    return shortName
  }, [provinceId])

  const [cities, setCities] = useState([])

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  const updateAccountSetupStore = (payload) => {
    dispatch(updateAccountSetupState(payload))
  }

  useEffect(() => {
    if (mappedProvinceGroupsList) {
      setCities(mappedProvinceGroupsList)
    }
  }, [mappedProvinceGroupsList])

  const { getCity, getPhoneNumbers } = brandServices

  // Reset store when option is toggled
  useEffect(() => {
    if (selectedPhoneNumberOption) {
      updatePhoneNumberStore({
        ...createNewNumberState,
        ...transferANumberCommonState,
        ...transferANumberWirelessState,
        ...transferANumberWirelineState
      })
    }
  }, [selectedPhoneNumberOption])

  const setStateFromCurrentSelection = (mappedCities) => {
    setNewNumber(phoneNumber)
    setIsNumbersLoaded(true)
    PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_GROUP].onChange(
      cityName,
      mappedCities,
      updatePhoneNumberStore
    )
  }

  const setProvinceFromProvinceId = (provId) => {
    const provinceInfo = getProvince(provId)
    if (provinceInfo) {
      const { label: { en = '', fr = '' } = {} } = provinceInfo
      const name = language === 'en' ? en : fr
      setProvinceValue(name)
      updateActivationProvince(name)
    }
  }

  const resetProvinceCityErrorState = () => {
    updatePhoneNumberStore({
      [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER_ERROR]: '',
      [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
    })
  }

  const resetCreateNumberState = () => {
    updatePhoneNumberStore({
      ...createNewNumberState
    })
  }

  const prevProvinceIdRef = useRef()
  useEffect(() => {
    const prevProvinceId = prevProvinceIdRef.current
    if (provinceId && provinceId !== prevProvinceId) {
      setIsCityLoading(true)
      getCity(provinceId)
        .then((result) => {
          if (provinceId === prevProvinceIdRef.current) {
            const resultCities = result.map((item) => {
              const { value, id } = item
              const cityName = value.split('-')[1]
              const name =
                cityName && cityName[0]
                  ? cityName[0].toUpperCase() + cityName.slice(1).toLowerCase()
                  : value
              return {
                value: id,
                name,
                groupName: value
              }
            })
            setAppState({ ...appState, [APPLICATION_STATE.PROVINCE_GROUPS]: resultCities })
            setLandingLogicLoader(false)
            setIsCityLoading(false)

            const changedCity = cityName && cityName !== cityValue
            
            if (provinceId) {
              setProvinceFromProvinceId(provinceId)
            }
            
            if (changedCity) {
              setCityValue(cityName)
            }
            
            if (phoneNumber && provinceId && changedCity) {
              setStateFromCurrentSelection(resultCities)
              return
            }

            setCityValue('')
          }
        })
        .catch((e) => {
          setIsCityLoading(false)
          console.log('error on city fetch', e)
        })
      if (cityValue) {
        setCityValue('')
        // Reset phone number when province is changed
        resetCreateNumberState()
      }
    } else {
      setAppState({ ...appState, [APPLICATION_STATE.PROVINCE_GROUPS]: [] })
    }
    resetProvinceCityErrorState()
    prevProvinceIdRef.current = provinceId
  }, [provinceId, getCity])

  const handlePlanValidation = (planAvailable) => {
    if (!planAvailable && (isPlanSelected === true || isPlanSelected === undefined)) {
      setValidPlanSelected(false)
      setShowProvinceError(false)
    } else if (UserData && !UserData?.portIn) {
      setNewNumber({
        id: UserData?.phoneNumberId,
        value: UserData?.phoneNumber
      })
      setValidPlanSelected(true)
    }
  }

  const updateActivationProvince = (name) => {
    PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE].onChange(
      name,
      provinces,
      errorCopy,
      showProvinceError,
      updatePhoneNumberStore
    )
    if (name) {
      AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.PROVINCE].onChange(
        name,
        provinces,
        updateAccountSetupStore
      )
    }
  }


  const fetchPhoneNumbers = () => {
    if (provinceGroupId && !isNumbersLoaded) {
      getPhoneNumbers(provinceGroupId, 1)
        .then((result) => {
          if (result === undefined) {
            setNewNumber('')
          } else if (result.length === 1 && result[0].value) {
            setNewNumber(result[0])
          }
          setIsNumbersLoaded(true)
        })
        .catch((err) => {
          console.log('Error', err)
        })
    }
  }

  useEffect(() => {
    fetchPhoneNumbers()
  }, [provinceGroupId])

  useEffect(() => {
    if (provinceIdError) {
      PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE].onBlur(
        phoneNumberState,
        provinces,
        errorCopy,
        provinceValue,
        updatePhoneNumberStore
      )
    }
    if (provinceGroupIdError) {
      PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_GROUP].onBlur(
        phoneNumberState,
        cities,
        updatePhoneNumberStore
      )
    }
  }, [language])

  useEffect(() => {
    if (provinceValue && provinceId) {
      const province = provinces.find(prov => prov.value === provinceId)
      if (province) {
        setProvinceValue(province.name)
      }
    }
  }, [language])

  useEffect(() => {
    if (!quoteProvId || UserData?.portIn) return
    if (
      quoteProvId &&
      UserData?.cityName &&
      UserData?.provinceGroupId &&
      !UserData?.phoneNumber &&
      !isNumbersLoaded &&
      cities?.length > 0
    ) {
      setShowPhoneNumberUnavailable(true)
    }
  }, [cities])

  const onProvinceChange = (event) => {
    const { value } = event.target
    if (value.length <= 25) {
      if (!showProvinceError) {
        setShowProvinceError(true)
      }
      setProvinceValue(event.target.value)
      updateActivationProvince(event.target.value)
      if (!value) {
        resetCreateNumberState()
        setCityValue("")
      }
    }
  }

  const onCityChange = (event) => {
    setIsNumbersLoaded(false)
    setCityValue(event.target.value)
    PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_GROUP].onChange(
      event.target.value,
      cities,
      updatePhoneNumberStore
    )
  }

  const handleSelectNewPhoneNumber = () => {
    setProvinceValue('')
    updateActivationProvince('')
    setCityValue('')
  }

  const handleSelectNewPhoneNumberIfUnavailable = () => {
    setCityValue(UserData.cityName)
    setProvinceFromProvinceId(provinceId)
    PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_GROUP].onChange(
      UserData.cityName,
      cities,
      updatePhoneNumberStore
    )
    setShowPhoneNumberUnavailable(false)
  }

  const handleSelectNewPlan = () => {
    if (isDealer) {
      history.push(dealerActivationPaths.ChangeNewPlan, {
        from: dealerActivationPaths.ChooseNumber
      })
    } else {
      history.push(consumerActivationPaths.ChangeNewPlan, {
        from: consumerActivationPaths.ChooseNumber
      })
    }
  }

  return (
    <>
      <div className="space-y-4 mt-6">
        <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6">
          <div ref={provinceRef} className="flex-1">
            <Datalist
              items={provinces}
              loading={provinces.length === 0}
              title={provinceLabel}
              onChange={onProvinceChange}
              onBlur={() => handleBlurProvince(provinceValue)}
              onFocus={() => {
                PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE].onFocus(
                  updatePhoneNumberStore
                )
              }}
              error={provinceIdError}
              id="phoneNumber-createProvince"
              value={provinceValue}
            />
          </div>
          <div ref={cityRef} className="flex-1">
            <Datalist
              items={provinceId ? cities : []}
              title={cityLabel}
              onChange={onCityChange}
              onBlur={() => handleBlurCity()}
              onFocus={() => {
                PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.PROVINCE_GROUP].onFocus(
                  updatePhoneNumberStore
                )
              }}
              error={errorCopy[provinceGroupIdError]}
              disabled={!provinceId || isCityLoading}
              loading={isCityLoading}
              id="phoneNumber-createCity"
              value={cityValue}
            />
          </div>
        </div>
        {provinceGroupId && isNumbersLoaded && !isCityLoading ? (
          <div className="space-y-4">
            <CreateNewNumber selectedNum={newNumber} />
          </div>
        ) : (
          <>
            {provinceGroupId && (
              <div className="flex justify-center space-y-4">
                <Loader loading />
              </div>
            )}
          </>
        )}
      </div>
      {preSelectedPlan && (
        <PlanUnavailablePopup
          province={currProvince}
          onClose={handleSelectNewPhoneNumber}
          onNewPhoneNumberClick={handleSelectNewPhoneNumber}
          onNewPlanClick={handleSelectNewPlan}
          onValidate={handlePlanValidation}
        />
      )}
      {validPlanSelected && (
        <PhoneNumberUnavailablePopup
          isOpen={showPhoneNumberUnavailable}
          unavailableNumberTitle={unavailableNumberTitle}
          unavailableNumberMessage={unavailableNumberMessage}
          selectNewPhoneNumberLabel={selectNewPhoneNumber}
          onClose={handleSelectNewPhoneNumberIfUnavailable}
          onNewPhoneNumberClick={handleSelectNewPhoneNumberIfUnavailable}
        />
      )}
    </>
  )
}

export default LocationSelector
