import React, { useContext, useRef, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Heading3 from '@apps/components/Typography/Heading3'
import TabGroup from '@apps/components/TabGroup'
import TabItem from '@apps/components/TabGroup/TabItem'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import ValidationCard from '@apps/components/Cards/ValidationCard'
import { PAYMENT_OPTIONS } from '@apps/utils/constants'
import { ContentContext } from '@apps/contexts/ContentContext'
import CreditDebitCard from '@apps/components/CreditDebitCard'
import InfoCard from '@apps/components/Cards/InfoCard'
import Checkbox from '@apps/components/Checkbox'
import BodySmall from '@apps/components/Typography/BodySmall'
import RichText from '@apps/components/RichText'
import Link from '@apps/components/Link'
import EPSCard from '@apps/components/CreditDebitCard/EPSCard'
import { getById, getImgByTitle } from '@apps/utils/contentful'
import VoucherOptionSection from './VoucherOptionSection'
import Sites from '@/sites'
import useSite from '@engine/hooks/useSite'
import BodyError from '@/apps/components/Typography/BodyError'

const PaymentOption = ({ showErrors, handleSIMActivation, textContent, setButtonIsLoading }) => {
  const site = useSite()
  const dispatch = useDispatch()
  const paymentState = useSelector((state) => state.payAndReviewPage)
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(PAYMENT_OPTIONS.CREDIT_CARD)
  const {
    dynamicContent
  } = useContext(ContentContext)
  
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      error: getImgByTitle(commonElements, 'error')?.url
    }
  }, [pageContent, selectedLanguage])

  let creditCardSavedContent = null
  let creditCardSavedContentDealer = null
  let atuConsumer = null
  let atuDealer = null
  if (dynamicContent) {
    // Let's discuss laster whether to fecth info realted to this place from contentful using getAllPagesData in Redux
    creditCardSavedContent = dynamicContent.paymentPage.content.find((item) => item.id === 'paymentPage-cc-consumer')
    creditCardSavedContentDealer = dynamicContent.paymentPage.content.find((item) => item.id === 'paymentPage-cc-dealer')
    atuConsumer = dynamicContent.paymentPage.content.find((item) => item.id === 'paymentPage-atu-consumer')
    atuDealer = dynamicContent.paymentPage.content.find((item) => item.id === 'paymentPage-atu-dealer')
  }

  const { paymentInformation: paymentInfo } = useSelector((state) => state.payAndReviewPage)

  // const [isTermsConditionsChecked, setIsTermsConditionsChecked] = useState()

  const {
    [PAYMENT_STATE.PAYMENT_GATEWAY]: paymentGateway,
    [PAYMENT_STATE.TERMS_OF_SERVICE]: isTermsConditionsChecked
  } = paymentState

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const onNumberOptionSelect = (name) => {
    setSelectedPaymentOption(name)
    updatePaymentStore({ 
      [PAYMENT_STATE.SELECTED_PAYMENT_OPTION] : name,
      [PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false
     })
  }

  const handleTermsOfCondition = () => {
    updatePaymentStore({[PAYMENT_STATE.TERMS_OF_SERVICE] : !isTermsConditionsChecked})
    // setIsTermsConditionsChecked(!isTermsConditionsChecked)
    // dispatch(setPaymentInformation({
    //   ...paymentInfo,
    //   termsOfService: !isTermsConditionsChecked
    // }))
  }

  return (
    <div className="space-y-8">
      <Heading3>{textContent?.shortTextFields?.paymentOptionsHeading}</Heading3>
      <div className="space-y-5">
        <TabGroup onSelect={onNumberOptionSelect}>
          <TabItem
            name={PAYMENT_OPTIONS.CREDIT_CARD}
            selected={selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD}
            data-testid="payment-payCredit"
          >
            <Heading3
              className={`text-center text-sm md:text-base lg:text-lg ${
                selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD && 'text-copy-inverted'
              }`}
            >
              {textContent?.shortTextFields?.payWithCardLabel}
            </Heading3>
          </TabItem>
          <TabItem
            name={PAYMENT_OPTIONS.VOUCHER}
            selected={selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER}
            data-testid="payment-payVoucher"
          >
            <Heading3
              className={`text-center text-sm md:text-base lg:text-lg ${
                selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER && 'text-copy-inverted'
              }`}
            >
              {textContent?.shortTextFields?.payWithVoucher}
            </Heading3>
          </TabItem>
        </TabGroup>
        {selectedPaymentOption === PAYMENT_OPTIONS.CREDIT_CARD
        &&
        (
          <div className="space-y-8">
             {(atuConsumer && (site === Sites.consumer)) ? (
              <InfoCard
              heading={atuConsumer.title}
              listItems={[<RichText key={atuConsumer.id}>{atuConsumer.body}</RichText>]}
            />
            ) : ( atuDealer &&
              <InfoCard
                heading= {atuDealer.title}
                listItems={[<RichText key={atuDealer.id}>{atuDealer.body}</RichText>]}
              />
            )}
            <div className="flex flex-col items-start w-full space-y-4 md:items-end lg:items-end lg:space-y-0 lg:space-x-4 md:flex-row lg:flex-row">
              <div className="w-full md:w-1/2 lg:w-1/2">

                {
                  paymentGateway === 'EPS'
                  &&
                  (
                    <EPSCard 
                      handleSIMActivation={handleSIMActivation}
                      showErrors={showErrors}
                      textContent={textContent}
                      setButtonIsLoading={setButtonIsLoading}
                    />            
                  )
                }
                {
                  paymentGateway === 'SALT'
                  &&
                  (
                    <CreditDebitCard showErrors={showErrors} textContent={textContent} />
                  )
                }

              </div>
              <div className="w-full lg:w-1/2 md:w-1/2">
              {(creditCardSavedContent && (site === Sites.consumer)) ? (
                 <ValidationCard>
                 <div className="pb-2">
                   <BodySmall>
                     <RichText>{creditCardSavedContent.body}</RichText>
                   </BodySmall>
                 </div>
               </ValidationCard>
              ) : (creditCardSavedContentDealer &&
                <ValidationCard>
                 <div className="pb-2">
                   <BodySmall>
                     <RichText>{creditCardSavedContentDealer.body}</RichText>
                   </BodySmall>
                 </div>
               </ValidationCard>
              )}
              </div>
            </div>
          </div>
        )
        }


        {selectedPaymentOption === PAYMENT_OPTIONS.VOUCHER && (
          <VoucherOptionSection
            handleSIMActivation={handleSIMActivation}
            showErrors={showErrors}
            textContent={textContent}
            setButtonIsLoading={setButtonIsLoading}
          />
        )}


        <div>
          <div className="flex flex-row w-full my-2 text-sm text-black">
            <div className="mr-2">
              <Checkbox
                hasError={showErrors && !isTermsConditionsChecked}
                checked={isTermsConditionsChecked}
                onChange={handleTermsOfCondition}
                testId="payment-termsCheck"
              />
            </div>
            <div>
              <Heading3 className="block tracking-wider text-md font-h1 text-primary">
                {textContent?.shortTextFields?.termsAgreement}
              </Heading3>

              <BodySmall>
                {textContent?.shortTextFields?.termsAgreementInfo}
                <Link target="_blank" href={textContent?.shortTextFields?.termsAgreementPolicyLink} className="font-h1">
                  {textContent?.shortTextFields?.termsAgreementPolicy}
                </Link>
                {', '}
                <Link target="_blank" href={textContent?.shortTextFields?.termsAgreementReferLink} className="font-h1">
                  {textContent?.shortTextFields?.termsAgreementRefer}
                </Link>
                {', '}
                <Link target="_blank" href={textContent?.shortTextFields?.termsAgreementPrivacyLink} className="font-h1">
                  {textContent?.shortTextFields?.termsAgreementPrivacy}
                </Link>
                {textContent?.shortTextFields?.termsAgreementPrivacySeparator}
                <Link target="_blank" href={textContent?.shortTextFields?.termsAgreementFairUseLink} className="font-h1">
                  {textContent?.shortTextFields?.termsAgreementFairUse}
                </Link>
              </BodySmall>
            </div>
          </div>
          {showErrors && !isTermsConditionsChecked && (
            <div className="flex items-center ml-8">
              <img className="h-4 my-2" src={content.error} alt="" />
              <BodyError className="ml-1 mt-0.5 leading-4">{textContent?.shortTextFields?.termsError}</BodyError>
            </div>
          )}
        </div>


      </div>
    </div>
  )
}

export default PaymentOption
