/* eslint-disable react/require-default-props */
import React, { useState, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Input from '@apps/components/Input'
import Heading3 from '@apps/components/Typography/Heading3'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import {
  setSimNumber,
  SIM_SELECTION_STATE,
  updateSimSelectionState
} from '@apps/redux/features/SimSelectionSlice'
import SimSelectionChangeHandlers, {
  SIM_SELECTION_EVENTS
} from '@apps/utils/ChangeHandlers/SimSelectionChangeHandlers'
import classNames from 'classnames'
import { getBySlug } from '@apps/utils/contentful'
import Sites from '@sites/index'
import { useDispatch, useSelector } from 'react-redux'
import { getById } from '@apps/utils/contentful'

const MAX_LENGTH = '28'

const SIM = ({ simRef, className = '', userType = '' }) => {
  const dispatch = useDispatch()
  const { pageContent } = useSelector((state) => state.common.content)

  const appState = useContext(ApplicationContext)
  const simSelectionState = useSelector((state) => state.simSelectionPage)
  const { isSimValidInfo } = useSelector((state) => state.common)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = appState
  const { isSimValid } = brandServices
  const { [SIM_SELECTION_STATE.SIM]: simValue, [SIM_SELECTION_STATE.SIM_ERROR]: error } =
    simSelectionState

  const simSelectionPageContent = useMemo(() => {
    if (!pageContent || !currentLanguage) return {}
    const page = getById(pageContent, `haveSimPage`)
    return page[currentLanguage]
  }, [pageContent, currentLanguage, userType])

  const updateSimSelectionStore = (newState) => {
    dispatch(updateSimSelectionState(newState))
  }

  const { shortTextFields } = simSelectionPageContent

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      simHeading: getBySlug(shortTextFields, 'simHeading')?.value,
      simLabel: getBySlug(shortTextFields, 'simLabel')?.value,
      simUnavailableError: getBySlug(shortTextFields, 'simUnavailableError')?.value,
      simRequiredError: getBySlug(shortTextFields, 'simRequiredError')?.value,
      simLengthError: getBySlug(shortTextFields, 'simLengthError')?.value
    }
  }, [shortTextFields])

  const { simRequiredError, simLengthError, simUnavailableError } = textContent

  useEffect(() => {
    if (simSelectionState.simError) {
      handleSimBlur(setLoading)
    }
  }, [currentLanguage])

  const [loading, setLoading] = useState(false)
  const errors = {
    simRequiredError,
    simLengthError,
    simUnavailableError
  }
  const onSimBlur = async () => {
    return SimSelectionChangeHandlers[SIM_SELECTION_EVENTS.SIM].onBlur(
      simSelectionState,
      isSimValid,
      setLoading,
      errors,
      updateSimSelectionStore
    )
  }
  useEffect(() => {
    ;(async () => {
      document.body.style.overflow = 'auto'
      await onSimBlur()
    })()
  }, [!isSimValidInfo])

  const updateStoreSimNumber = (newState) => {
    const simNumber = newState[SIM_SELECTION_STATE.SIM]
    dispatch(setSimNumber(simNumber))
  }

  const onSimNumberChange = (event) => {
    SimSelectionChangeHandlers[SIM_SELECTION_EVENTS.SIM].onChange(
      event.target.value,
      updateStoreSimNumber
    )
  }

  const handleSimBlur = async () => {
    const simResult = onSimBlur()
    if (simResult) {
      return true
    }
    return false
  }

  return (
    <div ref={simRef} className={classNames('relative', className)}>
      {textContent?.simHeading && userType === Sites.consumer && (
        <Heading3 className="pb-4">{textContent?.simHeading}</Heading3>
      )}
      <Input
        type="sim"
        id="sim"
        label={textContent?.simLabel}
        name="sim-number*"
        placeholder="XXXXX - XXXXX - XXXXX - XXXX"
        errorMessage={error}
        maxLength={MAX_LENGTH}
        value={simValue}
        onBlur={() => handleSimBlur(setLoading)}
        onChange={onSimNumberChange}
        loading={loading}
        valid={error === null}
        invalid={!loading && error === textContent?.simUnavailableError}
        data-testid="accountSetup-sim"
      />
    </div>
  )
}

SIM.prototype = {
  children: PropTypes.string
}

SIM.propTypes = {
  simRef: PropTypes.object.isRequired,
  className: PropTypes.string,
  userType: PropTypes.string
}

export default SIM
