/* eslint-disable react/require-default-props */
import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getById, getImgByTitle } from '@apps/utils/contentful'
import PropTypes from 'prop-types'

const HIDDEN = 'hidden'
const BLOCK = 'block'

const Expander = ({ heading, body, toggle = HIDDEN }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      chevronDownCircle: getImgByTitle(commonElements, 'chevronDownCircle')?.url,
      chevronUpCircle: getImgByTitle(commonElements, 'chevronUpCircle')?.url
    }
  }, [pageContent, selectedLanguage])

  const [toggleStatus, setToggleStatus] = useState(toggle)

  const toggleSection = () => {
    const updateToggle = toggleStatus
    setToggleStatus(updateToggle === HIDDEN ? BLOCK : HIDDEN)
  }

  return (
    <div className="divide-y divide-copy">
      <div className="py-8">
        <div onClick={() => toggleSection()} className="flex justify-between cursor-pointer">
          {heading}
          <img
            src={toggleStatus === HIDDEN ? content?.chevronDownCircle : content?.chevronUpCircle}
          />
        </div>
        <div className={`${toggleStatus} py-4`}>{body}</div>
      </div>
    </div>
  )
}

Expander.propTypes = {
  heading: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  toggle: PropTypes.oneOf([HIDDEN, BLOCK])
}

export default Expander
