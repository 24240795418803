/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useMemo, useEffect } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import PropTypes from 'prop-types'
import { getById, getBySlug } from '@apps/utils/contentful'
import BodyBold from '@/apps/components/Typography/BodyBold'
import Heading3 from '@/apps/components/Typography/Heading3'

const Modal = ({
  isOpen = false,
  closeModal,
  title = null,
  children,
  showControls = true,
  showBackLink = false,
  backLinkText = 'Go Back',
  disableClose = false
}) => {
  if (!isOpen) {
    return null
  }

  const { dynamicContent } = useContext(ContentContext)

  const { haveSimPage = {} } = dynamicContent || {}
  const { content, shortTextFields } = haveSimPage

  const pageContent = useMemo(() => {
    if (!content) return {}
    return {
      closeIcon: getById(content, 'close-icon')?.image?.url,
      backLinkText: getBySlug(shortTextFields, 'backlink-text')?.value
    }
  }, [content])

  useEffect(() => {
    if (!showControls && !disableClose) {
      const timer = setTimeout(() => {
        closeModal()
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [isOpen, closeModal, showControls])

  const handleClose = () => {
    if (disableClose) return
    if (closeModal) {
      closeModal()
    }
  }
  return (
    <div
      role="dialog"
      className="fixed inset-0 bg-gray-600 z-60 bg-opacity-75 flex justify-center items-center"
      onClick={handleClose}
    >
      <div className="relative">
        <div
          className={`bg-white rounded-lg max-w-2xl w-full p-8 ${!showControls ?? `min-h-128`}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={`flex justify-between ${showControls ?? 'mb-4'}`}>
            <Heading3 className="text-primary text-popUp leading-8">{title}</Heading3>

            {showControls && (
              <button type="button" onClick={closeModal} className="absolute top-4 right-4" data-testid="close-modal">
                <img alt="close" src={pageContent?.closeIcon} />
              </button>
            )}
          </div>

          <div>{children}</div>

          {showBackLink && (
            <div className="flex flex-col items-center my-2 md:my-4">
              <button onClick={closeModal} className="text-center">
                <BodyBold className="underline" textColor="text-primary">
                  {pageContent?.backLinkText ?? backLinkText}
                </BodyBold>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  showControls: PropTypes.bool,
  disableClose: PropTypes.bool,
  showBackLink: PropTypes.bool,
  backLinkText: PropTypes.string
}

export default Modal
