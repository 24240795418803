/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import BodyLarge from '@apps/components/Typography/BodyLarge'
import { PAYMENT_OPTIONS, PLAN_DURATION, PROMO_TYPE } from '@apps/utils/constants'
import { containsProvince } from '@apps/utils/common'
import ChoosePlanSection from '@apps/components/ChoosePlanSection'
import Plan from '@apps/components/Plan'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Sites from '@sites/index'
import { getById, getBySlug } from '@apps/utils/contentful'
import { useDispatch, useSelector } from 'react-redux'
import { PAYMENT_STATE, updatePaymentStates,referAFriend as referAFriendInitState } from '@apps/redux/features/PayAndReviewSlice'
import {
  loadAllPlans,
  setPlanOption,
  PLANS_STATE,
  updatePlansState
} from '@apps/redux/features/PlansSlice'
import { PHONE_NUMBER_OPTIONS } from '@apps/utils/constants'

const PlansGrid = ({ planErrorRef = null, showPlanError = false, setShowPlanError = null }) => {
  const dispatch = useDispatch()
  const { pageContent, featureFlags } = useSelector((state) => state.common.content)
  const { userType, currentProvinceId } = useSelector((state) => state.common)
  const { provinceId: provID, selectedPhoneNumberOption } = useSelector(
    (state) => state.phoneNumberPage
  )
  const {
    [PLANS_STATE.ALL_PLANS]: plans,
    [PLANS_STATE.PLAN]: plan,
    [PLANS_STATE.SELECTED_PLAN_OPTION]: selectedPlanOption,
    localizedPlanItem
  } = useSelector((state) => state.plansPage)

  const {
    [APPLICATION_STATE.SERVICES]: brandOperations,
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList,
    [APPLICATION_STATE.IDENTITY]: identity,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language
  } = useContext(ApplicationContext)

  const pagePrefix = userType === Sites.dealer ? 'Dealer' : 'Consumer'
  const plansPageContent = getById(pageContent, `plansPage${pagePrefix}`)[language]
  const { shortTextFields } = plansPageContent || {}

  const textContent = useMemo(() => {
    return {
      planError: getBySlug(shortTextFields, 'planError')?.value,
      selectProvinceForPlans: getBySlug(shortTextFields, 'selectProvinceForPlans')?.value
    }
  }, [shortTextFields])
  const { planError, selectProvinceForPlans } = textContent

  const [planSelected, setPlanSelected] = useState('')
  const [planList, setPlanList] = useState([])
  const paymentState = useSelector((state) => state.payAndReviewPage)

  const [localPlanOption, setLocalPlanOption] = useState('')

  const {
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo,
    [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: multiPromoCodes
  } = paymentState

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  useEffect(() => {
    const currentPlanIndex = planList?.findIndex((item) => item?.id === plan?.id)
    if (currentPlanIndex !== -1) {
      setPlanSelected(currentPlanIndex)
    }

    const defaultState = {
      [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: [],
      [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: [],
      ...referAFriendInitState,
      [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false,
      [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: PAYMENT_OPTIONS.CREDIT_CARD,
      [PAYMENT_STATE.CC_INFO.POSTAL_CODE]: '',
      [PAYMENT_STATE.CC_INFO.CC_API_VALIDATION_ERROR]: ''
    }
  
    if (featureFlags && featureFlags[0]?.enableMultiPromo && featureFlags[0]?.enableAutoPromo) {
      if (plan?.promo) {
        defaultState[PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO] = [plan.promo]
        defaultState[PAYMENT_STATE.PROMO.MULTI_PROMO_CODES] = [PROMO_TYPE.AUTOMATIC_PROMO]
      }
    }

    updatePaymentStore(defaultState)

  }, [plans, planSelected])

  const availablePlans = useMemo(() => {
    const usePhoneNumberProvince =
      selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER && provID
    const selectedProv = usePhoneNumberProvince
      ? mappedProvinceList?.find((pr) => pr.value === provID)?.shortName
      : mappedProvinceList?.find((pr) => pr.value === currentProvinceId)?.shortName
    const plansObject = localizedPlanItem?.[language]?.find(Boolean)
    const { plans: portalPlanList } = plansObject || {}
    return portalPlanList?.filter((pl) => !pl.province || containsProvince(pl.province, selectedProv))
  }, [
    mappedProvinceList,
    provID,
    currentProvinceId,
    language,
    localizedPlanItem,
    selectedPhoneNumberOption
  ])

  useEffect(() => {
    if (provID || currentProvinceId) {
      setPlanList(availablePlans)
      dispatch(loadAllPlans(availablePlans))
    } else {
      setPlanList([])
    }
  }, [availablePlans])

  const onPlanOptionSelect = (name) => {
    dispatch(setPlanOption(name))
    setLocalPlanOption(name)
  }

  const hasLTPPlans = () => {
    const result = planList?.filter((plan) => plan.duration === PLAN_DURATION.SLOT_360)
    return result?.length >= 1
  }

  const planListContainer = (planDuration) => {
    return (
      <div role="list" className="grid grid-col-1 lg:grid-cols-2 gap-0 sm:gap-7 auto-cols-auto">
        {planList?.map((plan, i) => {
          const { id, duration } = plan
          return (
            <>
              {duration === planDuration && (
                <Plan
                  key={id}
                  planSelected={choosePlan}
                  isSelected={planSelected === i}
                  plan={plan}
                  id={i}
                />
              )}
            </>
          )
        })}
      </div>
    )
  }

  /* istanbul ignore next */
  const choosePlan = (index, promo) => {
    if (
      featureFlags[0]?.enableMultiPromo &&
      multiPromotionCodeInfo &&
      multiPromotionCodeInfo?.length > 0 &&
      multiPromoCodes &&
      multiPromoCodes?.length > 0
    ) {
      const updatedPromotionCodeInfo = multiPromotionCodeInfo.filter(
        (code) => code?.id === promo?.id
      )
      const updatedPromoCodes = multiPromoCodes.filter(
        (code) => code === PROMO_TYPE.AUTOMATIC_PROMO
      )

      updatePaymentStore({
        ...paymentState,
        multiPromotionCodeInfo: updatedPromotionCodeInfo,
        multiPromoCodes: updatedPromoCodes,
        ...referAFriendInitState,
        [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
      })
    }

    if (
      featureFlags[0]?.enableMultiPromo &&
      multiPromotionCodeInfo?.length === 0 &&
      multiPromoCodes?.length === 0 &&
      promo
    ) {
      const newState = { ...paymentState }
      newState.multiPromotionCodeInfo = [...newState.multiPromotionCodeInfo, promo]
      newState.multiPromoCodes = [...newState.multiPromoCodes, PROMO_TYPE.AUTOMATIC_PROMO]
      updatePaymentStore({
        ...newState
      })
    }

    if (index === planSelected) {
      dispatch(
        updatePlansState({
          [PLANS_STATE.PLAN]: null,
          [PLANS_STATE.PROMO]: null
        })
      )
      updatePaymentStore({
        ...paymentState,
        multiPromotionCodeInfo: [],
        multiPromoCodes: [],
        ...referAFriendInitState,
        [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
      })

      setPlanSelected(null)
    } else {
      dispatch(
        updatePlansState({
          [PLANS_STATE.PLAN]: planList[index],
          [PLANS_STATE.PROMO]: promo
        })
      )
      setPlanSelected(index)
      setShowPlanError(false)
    }
  }

  if (planList?.length === 0) {
    return (
      <div ref={planErrorRef} className="flex justify-center py-8 mt-4 bg-white">
        <BodyLarge>{selectProvinceForPlans}</BodyLarge>
      </div>
    )
  }

  return (
    <div ref={planErrorRef}>
      {showPlanError && (
        <div className="flex justify-center py-8 my-4 bg-white">
          <BodyLarge className="text-error">{planError}</BodyLarge>
        </div>
      )}
      {!hasLTPPlans() && (
        <div role="list" className="grid grid-col-1 lg:grid-cols-2 gap-0 sm:gap-7 auto-cols-auto">
          {planList?.map((plan, i) => {
            const { id } = plan
            return (
              <Plan
                key={id}
                planSelected={choosePlan}
                isSelected={planSelected === i}
                plan={plan}
                id={i}
              />
            )
          })}
        </div>
      )}
      {hasLTPPlans() && (
        <>
          <ChoosePlanSection selectedOption={selectedPlanOption} onSelect={onPlanOptionSelect} />
          <>
            {(localPlanOption || selectedPlanOption) === PLAN_DURATION.SLOT_30 && (
              <div className="text-black space-y-4">{planListContainer(PLAN_DURATION.SLOT_30)}</div>
            )}
            {(localPlanOption || selectedPlanOption) === PLAN_DURATION.SLOT_360 && (
              <div className="text-black space-y-4">
                {planListContainer(PLAN_DURATION.SLOT_360)}
              </div>
            )}
          </>
        </>
      )}
    </div>
  )
}

PlansGrid.propTypes = {
  planList: PropTypes.arrayOf(PropTypes.object),
  planErrorRef: PropTypes.object,
  showPlanError: PropTypes.bool,
  setShowPlanError: PropTypes.func
}

export default PlansGrid
