export const BrandName = 'koodo'

export const PHONE_NUMBER_OPTIONS = {
  CHOOSE_A_NEW_NUMBER: 'CHOOSE_A_NEW_NUMBER',
  TRANSFER_NUMBER: 'TRANSFER_NUMBER'
}

export const PLAN_DURATION = {
  SLOT_30: 30,
  SLOT_360: 360
}

export const PAYMENT_OPTIONS = {
  CREDIT_CARD: 'creditCard',
  VOUCHER: 'voucher'
}

export const CARD_VALIDATION_ERRORS = {
  UNAUTHORIZED: 'unauthorized',
  FAILED: 'failed'
}

export const PAGE_NUMBER = {
  ACCOUNT_SETUP: 1,
  SIM_SELECTION: 2,
  CHOOSE_NUMBER: 3,
  PLAN_SELECTION: 4,
  PAYMENT: 5,
  PLAN_PRESELECTED_PAYMENT: 4
}

export const SPIDS = [
  { value: 'Aliant', name: 'Aliant' },
  { value: 'AstraQom', name: 'AstraQom' },
  { value: 'Bell Canada', name: 'Bell Canada' },
  { value: 'Bragg Communications', name: 'Bragg Communications' },
  { value: 'Brooke Telecom', name: 'Brooke Telecom' },
  { value: 'Chatr Mobile', name: 'Chatr Mobile' },
  { value: 'Cityfone', name: 'Cityfone' },
  { value: 'CityWest', name: 'CityWest' },
  { value: 'Cogeco', name: 'Cogeco' },
  { value: 'Comwave', name: 'Comwave' },
  { value: 'Dave Wireless', name: 'Dave Wireless' },
  { value: 'DCI Telecom', name: 'DCI Telecom' },
  { value: 'Distributel', name: 'Distributel' },
  { value: 'Eastlink', name: 'Eastlink' },
  { value: 'Ensync', name: 'Ensync' },
  { value: 'ExaTel', name: 'ExaTel' },
  { value: 'Fibernetics', name: 'Fibernetics' },
  { value: 'Fido', name: 'Fido' },
  { value: 'Fizz', name: 'Fizz' },
  { value: 'Fongo', name: 'Fongo' },
  { value: 'Freedom Mobile', name: 'Freedom Mobile' },
  { value: 'Future Way', name: 'Future Way' },
  { value: 'Globility Communications', name: 'Globility Communications' },
  { value: 'GT Group Telecom', name: 'GT Group Telecom' },
  { value: 'Harmony', name: 'Harmony' },
  { value: 'Hay Communications Co-operative', name: 'Hay Communications Co-operative' },
  { value: 'Hurontel', name: 'Hurontel' },
  { value: 'Iristel', name: 'Iristel' },
  { value: 'ISP Telecom', name: 'ISP Telecom' },
  { value: 'Public Mobile', name: 'Public Mobile' },
  { value: 'KORE', name: 'KORE' },
  { value: 'Lucky Mobile', name: 'Lucky Mobile' },
  { value: 'MaskaTel', name: 'MaskaTel' },
  { value: 'Mobilicity', name: 'Mobilicity' },
  {
    value: 'Mornington Communications Co-operative',
    name: 'Mornington Communications Co-operative'
  },
  { value: 'MTS Allstream', name: 'MTS Allstream' },
  { value: 'NorthernTel', name: 'NorthernTel' },
  { value: 'Northwestel', name: 'Northwestel' },
  { value: 'Novus', name: 'Novus' },
  { value: 'PC Mobile', name: 'PC Mobile' },
  { value: 'Primus', name: 'Primus' },
  { value: 'Quadro Communication Co-operative', name: 'Quadro Communication Co-operative' },
  { value: 'Rogers', name: 'Rogers' },
  { value: 'SaskTel', name: 'SaskTel' },
  { value: 'Sears', name: 'Sears' },
  { value: 'Setaia', name: 'Setaia' },
  { value: 'Shaw', name: 'Shaw' },
  { value: 'Solo Mobile', name: 'Solo Mobile' },
  { value: 'TBayTel', name: 'TBayTel' },
  { value: 'TekSavvy', name: 'TekSavvy' },
  { value: 'Telebec', name: 'Télébec' },
  { value: 'TELUS', name: 'TELUS' },
  {
    value: 'Tuckersmith Communication Co-operative',
    name: 'Tuckersmith Communication Co-operative'
  },
  { value: 'Vianet', name: 'Vianet' },
  { value: 'Videotron ', name: 'Vidéotron ' },
  { value: 'Virgin Mobile', name: 'Virgin Mobile' },
  { value: 'Ztar Mobile', name: 'Ztar Mobile' },
  { value: '7-11 SpeaksOut Wireless', name: '7-11 SpeaksOut Wireless' }
]

export const LANGUAGES_CODE = {
  EN: 'en',
  FR: 'fr'
}

export const LANGUAGES = [
  { value: LANGUAGES_CODE.EN, label: 'English' },
  { value: LANGUAGES_CODE.FR, label: 'Français' }
]

export const PORT_ORDER_AUTH_TYPE = {
  ACCOUNT: 'account',
  SERIAL_NUMBER: 'serial',
  PIN: 'PIN',
  NONE: 'none'
}

export const PROMO_TYPE = {
  AUTOMATIC_PROMO: '$AUTOMATIC_PROMO$'
}

export const VALID_AVS_CODES = ['D', 'M', 'X', 'Y', 'A', 'B', 'W', 'Z', 'P', 'S']

export const CONSUMER_SIM_OPTIONS = {
  HAVE_SIM: 'haveSIM',
  NEW_SIM: 'newSIM'
}

export const SIM_OPTIONS = {
  P_SIM: 'pSIM',
  E_SIM: 'eSIM'
}

export const SIM_COST = {
  pSIM: 0,
  eSIM: 5
}

export const CONSUMER_ESIM_OPTIONS = {
  NEW_E_SIM: 'eSim',
  NEW_SIM_CARD: 'pSIM'
}

export const FR_TAX_TYPE_LABELS = {
  HST: 'TVH',
  QST: 'TVQ',
  GST: 'TPS',
  PST: 'TVP'
}